import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: "/",
        name: "KarmantikaHome",
        component: () => import(
    /* webpackChunkName: "KarmantikaHome" */ '../views/KarmantikaHome.vue')
    },
    {
        path: "/register",
        name: "RegisterEmployee",
        component: () => import(
            /* webpackChunkName: "RegisterEmployee" */ '../views/RegisterEmployee.vue')
    },
    {
        path: "/login",
        name: "ProfessionalLogin",
        component: () => import(
            /* webpackChunkName: "RegisterEmployee" */ '../views/ProfessionalLogin.vue')
    },
    {
        path: "/:catchAll(.*)",
        component: () => import(
            /* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
      },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
